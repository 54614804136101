var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "user-page" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
            [
              _c(
                "mega-drop-zone",
                {
                  staticClass: "mb-3",
                  attrs: { type: ["image/jpeg", "image/png"] },
                  on: { change: _vm.renderFile }
                },
                [
                  !_vm.avatar
                    ? _c("mega-image", { attrs: { ratio: "1x1", src: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.avatar
                    ? _c("img", {
                        staticClass: "w-100",
                        class: { "opacity-0": _vm.rendering },
                        staticStyle: { transition: ".1s" },
                        attrs: { alt: "", src: _vm.avatar }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("mega-card", { staticClass: "card-border card-round" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("mega-input", {
                      attrs: { label: _vm.$t("arr_profile_fname") },
                      model: {
                        value: _vm.user["first_name"],
                        callback: function($$v) {
                          _vm.$set(_vm.user, "first_name", $$v)
                        },
                        expression: "user['first_name']"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("arr_profile_lname") },
                      model: {
                        value: _vm.user["last_name"],
                        callback: function($$v) {
                          _vm.$set(_vm.user, "last_name", $$v)
                        },
                        expression: "user['last_name']"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      style: "" + (_vm.validNick ? "" : "margin-bottom: 0"),
                      attrs: {
                        label: _vm.$t("arr_profile_uname"),
                        valid: _vm.validNick
                      },
                      model: {
                        value: _vm.user.username,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "username", $$v)
                        },
                        expression: "user.username"
                      }
                    }),
                    _vm._v(" "),
                    !_vm.validNick
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("arr_profile_uname_exist")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: "Email" },
                      model: {
                        value: _vm.user.email,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-select", {
                      attrs: { label: _vm.$t("sex"), options: _vm.getSexTypes },
                      model: {
                        value: _vm.user["idc_sex"],
                        callback: function($$v) {
                          _vm.$set(_vm.user, "idc_sex", $$v)
                        },
                        expression: "user['idc_sex']"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("arr_phone") },
                      model: {
                        value: _vm.user["phone"],
                        callback: function($$v) {
                          _vm.$set(_vm.user, "phone", $$v)
                        },
                        expression: "user['phone']"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body pt-0" },
                  [
                    _c(
                      "mega-button",
                      {
                        staticClass: "btn w-100 btn-success",
                        attrs: { loading: _vm.loading },
                        on: { click: _vm.submit }
                      },
                      [_vm._v(_vm._s(_vm.$t("model_save")))]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
            [
              _c("div", { staticClass: "d-flex mt-4" }, [
                _c("h4", { staticClass: "bold text-muted text-dark" }, [
                  _vm._v(_vm._s(_vm.$t("activity")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c(
                  "div",
                  { staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6" },
                  [
                    _c(
                      "mega-card",
                      {
                        staticClass: "rounded",
                        attrs: { loading: !_vm.rounds.loaded }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "strong text-success mb-0",
                              staticStyle: { "line-height": "1" }
                            },
                            [_vm._v(_vm._s(_vm.rounds.count))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm.$t("rounds")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-media rounded-bottom" },
                          [
                            _c(
                              "mega-chart",
                              {
                                attrs: {
                                  ratio: "10x3",
                                  labels: Object.keys(_vm.dataset.rounds),
                                  clear: "y",
                                  offset: 10
                                }
                              },
                              [
                                _c("mega-chart-set", {
                                  attrs: {
                                    title: "Rounds",
                                    data: Object.values(_vm.dataset.rounds),
                                    color: "success",
                                    fill: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sm-down:col-12 md:col-6 lg:col-12 xl:col-6" },
                  [
                    _c(
                      "mega-card",
                      {
                        staticClass: "rounded",
                        attrs: { loading: !_vm.rounds.loaded }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "strong text-warning mb-0",
                              staticStyle: { "line-height": "1" }
                            },
                            [_vm._v(_vm._s(_vm.sessions.count))]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(_vm.$t("sessions")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-media rounded-bottom" },
                          [
                            _c(
                              "mega-chart",
                              {
                                attrs: {
                                  ratio: "10x3",
                                  labels: Object.keys(_vm.dataset.sessions),
                                  clear: "y",
                                  offset: 10
                                }
                              },
                              [
                                _c("mega-chart-set", {
                                  attrs: {
                                    title: "Sessions",
                                    data: Object.values(_vm.dataset.sessions),
                                    color: "warning",
                                    fill: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.rounds.loaded && _vm.rounds.count > 0
                ? _c(
                    "section",
                    [
                      _c("div", { staticClass: "d-flex mt-4" }, [
                        _c("h4", { staticClass: "bold text-muted text-dark" }, [
                          _vm._v(_vm._s(_vm.$t("last_rounds")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "mega-slider",
                        { staticClass: "row", attrs: { options: _vm.slider } },
                        _vm._l(_vm.rounds.list, function(round, i) {
                          return _c("mega-slide", { key: i }, [
                            _c(
                              "div",
                              { staticClass: "px-3" },
                              [
                                _c("round-card", {
                                  staticClass: "cursor-grab",
                                  attrs: { data: round }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sessions
                ? _c(
                    "mega-card",
                    {
                      staticClass: "mt-4",
                      attrs: { title: _vm.$t("sessions") }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        _vm._l(_vm.sessions.list, function(session, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass:
                                "w-100 navbar-label align-items-center text-dark",
                              class: { "mt-3": i > 0 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mr-3 sticker sticker-sm navbar-avatar bg-light",
                                  class: {
                                    "bg-success": session.device === "i-android"
                                  }
                                },
                                [_c("i", { class: session.device })]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _c("small", { staticClass: "strong" }, [
                                  _c("span", [_vm._v(_vm._s(session.datetime))])
                                ]),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("IP: " + _vm._s(session.ip))
                                ])
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }