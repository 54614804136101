<template>
    <main class="user-page">
        <section>
            <div class="container py-5">
                <div class="row">

                    <div class="md-down:col-12 lg:col-5 xl:col-3">

                        <mega-drop-zone class="mb-3" @change="renderFile" :type="['image/jpeg', 'image/png']">
                            <mega-image ratio="1x1" :src="''" v-if="!avatar"/>
                            <img class="w-100"  alt="" style="transition: .1s"
                                 :src="avatar"
                                 v-if="avatar"
                                 :class="{'opacity-0': rendering}">
                        </mega-drop-zone>

                        <mega-card class="card-border card-round">

<!--                            <nav class="nav flex-column font-small">-->
<!--                                <div class="nav-text text-muted">-->
<!--                                    <i class="i-user-plus"></i>-->
<!--                                    <span>{{user['a_time'] | u2d('DD.MM.Y')}}</span>-->
<!--                                </div>-->
<!--                            </nav>-->

                            <div class="card-body">
                                <mega-input :label="$t('arr_profile_fname')" v-model="user['first_name']"/>
                                <mega-input :label="$t('arr_profile_lname')" v-model="user['last_name']"/>
                                <mega-input 
                                    :label="$t('arr_profile_uname')" 
                                    v-model="user.username" 
                                    :valid="validNick"
                                    :style="`${ validNick ? '' : 'margin-bottom: 0' }`"/>
                                <span v-if="!validNick" class="text-danger">{{ $t('arr_profile_uname_exist') }}</span>
                                <mega-input label="Email" v-model="user.email"/>

                                <mega-select :label="$t('sex')"
                                             v-model="user['idc_sex']"
                                             :options="getSexTypes"/>

                                <mega-input :label="$t('arr_phone')" v-model="user['phone']"/>
                                <!-- <mega-mask :label="$t('arr_phone')" unmask
                                           v-model="user.phone"
                                           :mask="{mask: '+{7} (000[0]) 000-00-00', lazy: false}"/> -->
                            </div>

                            <div class="card-body pt-0">
                                <mega-button class="btn w-100 btn-success" :loading="loading" @click="submit">{{ $t('model_save') }}</mega-button>
                            </div>
                        </mega-card>
                    </div>

                    <div class="md-down:col-12 lg:col-7 xl:col-9">

                        <!-- <mega-card :title="user.name">
                            <div class="card-body">
                                <strong>{{ $t('sex') }}:</strong> {{user['sex']}}
                            </div>
                        </mega-card> -->

                        <div class="d-flex mt-4">
                            <h4 class="bold text-muted text-dark">{{ $t('activity') }}</h4>
                        </div>

                        <div class="row no-select">

                            <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                                <mega-card class="rounded" :loading="!rounds.loaded">

                                    <div class="card-body">
                                        <h3 class="strong text-success mb-0" style="line-height: 1">{{rounds.count}}</h3>
                                        <span class="text-muted">{{ $t('rounds') }}</span>
                                    </div>

                                    <div class="card-media rounded-bottom">
                                        <mega-chart ratio="10x3" :labels="Object.keys(dataset.rounds)" clear="y" :offset="10">
                                            <mega-chart-set title="Rounds" :data="Object.values(dataset.rounds)" color="success" fill/>
                                        </mega-chart>
                                    </div>
                                </mega-card>
                            </div>

                            <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                                <mega-card class="rounded" :loading="!rounds.loaded">

                                    <div class="card-body">
                                        <h3 class="strong text-warning mb-0" style="line-height: 1">{{sessions.count}}</h3>
                                        <span class="text-muted">{{ $t('sessions') }}</span>
                                    </div>

                                    <div class="card-media rounded-bottom">
                                        <mega-chart ratio="10x3" :labels="Object.keys(dataset.sessions)" clear="y" :offset="10">
                                            <mega-chart-set title="Sessions" :data="Object.values(dataset.sessions)" color="warning" fill/>
                                        </mega-chart>
                                    </div>
                                </mega-card>
                            </div>
                        </div>

                        <section v-if="rounds.loaded && rounds.count > 0">
                            <div class="d-flex mt-4">
                                <h4 class="bold text-muted text-dark">{{ $t('last_rounds') }}</h4>
<!--                                <router-link class="btn btn-sm ml-auto" :to="{name: 'User_Rounds'}">View All</router-link>-->
                            </div>

                            <mega-slider class="row" :options="slider">
                                <mega-slide v-for="(round, i) in rounds.list" :key="i">
                                    <div class="px-3">
                                        <round-card class="cursor-grab" :data="round"/>
                                    </div>
                                </mega-slide>
                            </mega-slider>
                        </section>

                        <mega-card class="mt-4" :title="$t('sessions')" v-if="sessions">
                            <div class="card-body">

                                <div class="w-100 navbar-label align-items-center text-dark"
                                     :class="{'mt-3': i > 0}"
                                     v-for="(session, i) in sessions.list"
                                     :key="i">

                                    <span class="mr-3 sticker sticker-sm navbar-avatar bg-light"
                                          :class="{'bg-success': session.device === 'i-android'}">
                                        <i :class="session.device"></i>
                                    </span>

                                    <div class="content">
                                        <small class="strong">
                                            <span>{{session.datetime}}</span>
                                        </small>
                                        <small class="text-muted">IP: {{session.ip}}</small>
                                    </div>
                                </div>
                            </div>
                        </mega-card>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import RoundCard from '../Rounds/components/round-card'

    import SexTypes from '../../../data/sex_types.json'

    export default {
        name: 'UserPage',
        data() {
            return {
                loading: true,
                rendering: false,
                avatar: undefined,
                year: this.$u2d(new Date(), 'YYYY'),
                sexTypes: SexTypes,
                dataset: {
                    rounds: this.months(),
                    sessions: this.months()
                },
                sessions: {
                    count: 0,
                    loaded: false,
                    list: []
                },
                rounds: {
                    count: 0,
                    loaded: false,
                    list: []
                },
                slider: {
                    slidesPerView: 3,
                    breakpoints: {
                        750: {
                            slidesPerView: 1
                        },
                        990: {
                            slidesPerView: 2
                        },
                        1100: {
                            slidesPerView: 1
                        },
                        1470: {
                            slidesPerView: 2
                        }
                    }
                },
                user: {
                    loaded: false
                },
                validNick: true, 
            }
        },
        mounted() {

			this.$navbar.name = 'User';

            this.getUser();
            this.getRounds();
			this.getSessions();

			//this.sexTypes = this.getSexTypes;
		},
		computed: {
			getSexTypes() {
				return this.sexTypes.map((type, index) => ({
						value: type.value,
						name: type.name = this.$t(`sex_type_${ index + 1 }`)
					})
				);
			},
		},
        methods: {

            getUser() {

                this.$api.v1.get('/user/profile')
                    .then(response => {
                        this.user = response.data.profile;
                        this.loading = false;
                        this.user.loaded = true;

                        this.$navbar.name = `${ this.$t('user') } #${ this.user.idt_user } | ${ this.user.name }`;

                        if(this.user['idc_sex'] === 1) this.user.sex = this.$t('sex_type_1');
                        if(this.user['idc_sex'] === 2) this.user.sex = this.$t('sex_type_2');
                        if(this.user['idc_sex'] === 3) this.user.sex = this.$t('sex_type_3');

                        if(this.user['avatar'])
                            this.$render(this.user['avatar'])
                                .then(src => { this.avatar = src })
                    })
                    .catch(() => {
                        this.$router.replace('/404')
                    });
            },

            getRounds() {

                this.$api.v2.get('/user/feed')
                    .then(response => {

                        let rounds = response.data.rounds;
                        this.rounds.count = response.data.count;

                        if(rounds.length > 6) rounds.length = 6;
                        this.rounds.list = rounds;

                        this.rounds.loaded = true;

                        Object.keys(this.dataset.rounds).map(k => { this.dataset.rounds[k] = 0 });

                        rounds.map(round => {

                            let month = this.$u2d(round['a_time'], 'MMM');
                            let year = this.$u2d(round['a_time'], 'YYYY');

                            if(year === this.year)
                                this.dataset.rounds[month]++;
                        });
                    })
                    .catch(() => {
                        this.rounds.loaded = true;
                    });
            },

            getSessions() {

                this.$api.v1.get('/user/sessionList')
                    .then(response => {

                        let sessions = response.data.sessions;
                        this.sessions.count = response.data.sessions.length;
                        this.sessions.loaded = true;

                        sessions.map(session => {

                            this.sessions.list.push({
                                ip: session['ip'],
                                device: this.device(session['user_agent']),
                                datetime: this.$u2d(session['a_time'], 'DD.MM.YYYY - LT')
                            })

                            let month = this.$u2d(session['a_time'], 'MMM');
                            let year = this.$u2d(session['a_time'], 'YYYY');

                            if(year === this.year)
                                this.dataset.sessions[month]++;
                        });
                    })
                    .catch(() => {
                        this.sessions.loaded = true;
                    });
			},

            months() {
				let month = new Date().getMonth();
				let arr = {};
				let months = [
					this.$t('date.months.short.jan'), 
					this.$t('date.months.short.feb'),
					this.$t('date.months.short.mar'),
					this.$t('date.months.short.apr'),
					this.$t('date.months.short.may'),
					this.$t('date.months.short.jun'),
					this.$t('date.months.short.jul'),
					this.$t('date.months.short.aug'),
					this.$t('date.months.short.sep'),
					this.$t('date.months.short.oct'),
					this.$t('date.months.short.nov'),
					this.$t('date.months.short.dec'), 
				];

				months.filter((m, i) => i <= month).map(m => { arr[m] = 0 });

				return arr;
			},

            device(user_agent) {
                let d = undefined;

                let s = user_agent.toLowerCase();

                d = 'i-monitor';
                if(s.includes('ios')) d = 'i-apple';
                if(s.includes('android')) d = 'i-android';

                return d;
            },

            renderFile(file) {
                this.loading = true;
                this.rendering = true;

                let formData = new FormData();
                formData.append("image", file);

                this.$api.v1.put('/user/avatar', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => {
                        this.loading = false;

                        this.$alert.success('Avatar was updated');

                        let img = new Image();
                        img.src = response.data['profile']['avatar'];

                        setTimeout(() => { this.avatar = img.src; }, 100)

                        img.onload = () => {
                            setTimeout(() => { this.rendering = false }, 200)
                        }
                    })
            },

            submit() {
                this.loading = true;
                this.validNick = true;

                let data = this.user;
                let formData = {};

                data['height'] = data['height'] * 0.01;

                Object.keys(data).map(prop => {
                    if(data[prop]) formData[prop] = data[prop];
                });

                this.$api.v1.put('/user/profile', formData)
                    .then(() => {
                        this.loading = false;
                        this.getUser();
                        this.$alert.success('Data was updated');
                    })
                    .catch(({ response }) => {
                        this.loading = false;
                        if (response.status === 400) {
                            if (response.data.messages[0] == 'username exists' ) {
                                this.validNick = false;
                            }
                        }
                    })
            }
        },
        components: {
            RoundCard
        }
    }
</script>
